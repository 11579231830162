import React, { useEffect, useState } from "react";
import { TRYON_SERVER_URL } from "../config";
import PictureCard from "./PictureCard";
import errorIcon from "../assets/errorIcon.svg";
import GradientFont from "./GradientFont";
import ThreeDotSpinner from "./ThreeDotSpinner";

export default function OutfitCaptionCard({ experiment }) {
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [outputFormat, setOutputFormat] = useState(null)

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    };

    const handleExport = () => {
        try {
            // Check if experiment.output is valid
            if (experiment?.output) {
                const captionData = JSON.parse(experiment.output).caption;
                const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(captionData));
                const dlAnchorElem = document.getElementById('downloadAnchorElem');

                // Ensure the anchor element exists
                if (dlAnchorElem) {
                    dlAnchorElem.setAttribute("href", dataStr);
                    dlAnchorElem.setAttribute("download", "caption.json");
                    dlAnchorElem.click();
                } else {
                    console.error("Download anchor element not found.");
                }
            } else {
                console.error("No output data available to export.");
            }
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    useEffect(()=>{
        if(outputFormat === null){
            Object.entries(JSON.parse(experiment?.params)).map(([key, value]) => {
                if(key === "output_format"){
                    setOutputFormat(value)
                }
            })
        }
    }, [])

    return (
        <div className={`flex flex-col md:flex-row justify-start rounded-lg border-2 border-lite-red ${
            experiment?.status === "failed" ? 'border-red-500' : 'border-gray-200'
        } p-4 bg-white shadow-sm gap-4`}>
        
            <div className="w-full md:w-1/3 lg:w-1/3 h-full">
                
                    <PictureCard 
                        experiment={experiment} 
                        imageUrl={experiment?.garment?.image_url} 
                        label={"Outfit"} 
                        index={0}
                        TRYON_SERVER_URL={TRYON_SERVER_URL} 
                        loading={false} 
                    />
            
            </div>
            {experiment?.status === "pending" || experiment?.status === "running" ? (
                    <div className="flex flex-col items-center justify-center h-full w-full md:w-2/3">
                        <ThreeDotSpinner />
                    </div>
                ) : (
            <div className="flex flex-col justify-between items-start w-full md:w-2/3 h-full">
                <div className="font-semibold text-gray-800 text-lg border-b-2 border-gray-200 pb-2 mb-2">
                    Params:
                </div>
                <div className="flex-1 mt-2 w-full">
                    <div className="flex flex-col items-start gap-2">
                    
                        {experiment?.params !== null && experiment?.params !== undefined ? (
                            Object.entries(JSON.parse(experiment?.params)).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1);
                                return (
                                    <div 
                                        key={key} 
                                        className="flex items-center justify-start bg-gradient-to-r from-gray-50 to-gray-100 
                                        shadow-sm border border-red-200 rounded-full px-4 py-1.5 hover:shadow-md 
                                        transition-all duration-200 ease-in-out"
                                    >
                                        <span className="text-sm font-semibold text-gray-600 my-auto">
                                            {formattedKey.split(' ').map(word => 
                                                word.charAt(0).toUpperCase() + word.slice(1)
                                            ).join(' ')}:
                                        </span>
                                        <span className="ml-1.5 text-sm font-medium text-gray-500 my-auto">
                                            {value.toString().split(' ').map(word => 
                                                word.charAt(0).toUpperCase() + word.slice(1)
                                            ).join(' ')}
                                        </span>
                                    </div>
                                )
                            })
                        ) : (<></>)}
                    </div>
                </div>

                <div className="w-full flex justify-between items-center mb-2 mt-4">
                    <div className="font-semibold text-gray-800 text-lg border-b-2 border-gray-200 pb-2">
                        Caption:
                    </div>
                    <button 
                        className="py-1 px-3 text-sm bg-gradient-to-r from-red-500 to-rose-600 rounded-full 
                        text-white cursor-pointer transition duration-200 ease-in-out hover:scale-105 
                        hover:shadow-lg"
                        onClick={handleExport}
                    >
                        Export
                    </button>
                </div>

                <div className="flex-1 mt-2 w-full">
                    <div className="flex flex-col h-full w-full items-start gap-2">
                        <div className="flex justify-start gap-2 flex-wrap h-full w-full">
                            {experiment?.output !== null && experiment?.output !== undefined ? (
                                outputFormat !== null?
                                outputFormat === "json"?
                                Object.entries(JSON.parse(experiment?.output).caption).map(([key, value]) => {
                                    const formattedKey = key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1);
                                    return (
                                        <div 
                                            key={key} 
                                            className="flex items-center justify-start bg-gradient-to-r from-gray-50 to-gray-100 
                                            shadow-sm border border-red-200 rounded-full px-4 py-1.5 hover:shadow-md 
                                            transition-all duration-200 ease-in-out"
                                        >
                                            <span className="text-sm font-semibold text-gray-600 my-auto">
                                                {formattedKey.split(' ').map(word => 
                                                    word.charAt(0).toUpperCase() + word.slice(1)
                                                ).join(' ')}:
                                            </span>
                                            <span className="ml-1.5 text-sm font-medium text-gray-500 my-auto">
                                                {value.toString().split(' ').map(word => 
                                                    word.charAt(0).toUpperCase() + word.slice(1)
                                                ).join(' ')}
                                            </span>
                                        </div>
                                    )
                                })
                                : <p>{JSON.parse(experiment?.output).caption.slice(0, 700)}... </p>
                                : (
                                    <ThreeDotSpinner />
                                )
                            ) : experiment?.status === "failed" && (
                                <div className="inset-0 backdrop-blur-sm lg:col-span-4 rounded-2xl w-full h-full">
                                    <div className="w-full h-full flex items-center justify-center">
                                        <div className="bg-white/90 rounded-xl w-full p-6 shadow-sm">
                                            <div className="flex flex-col items-center space-y-4 text-center">
                                                <div className="flex items-center justify-center">
                                                    <img 
                                                        src={errorIcon} 
                                                        alt="error" 
                                                        className='w-6 h-6 md:w-8 md:h-8 mr-2' 
                                                    />
                                                    <GradientFont>FAILED</GradientFont>
                                                </div>
                                                <p className="text-sm md:text-base text-gray-700">
                                                    Outfit caption generation failed. Try again or contact us at{' '}
                                                    <a 
                                                        href="mailto:contact@tryonlabs.ai" 
                                                        className="underline text-[#BE3144] hover:text-[#BE3144]/80 transition-colors"
                                                    >
                                                        contact@tryonlabs.ai
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>)}

            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white rounded-lg p-4">
                        <button
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                            onClick={handleCloseModal}
                        >
                            &times;
                        </button>
                        <img src={modalImage} alt="Enlarged" className="max-w-full max-h-full" />
                    </div>
                </div>
            )}

            {/* Hidden anchor element for downloading */}
            <a id="downloadAnchorElem" style={{ display: 'none' }}></a>
        </div>
    );
}