import BuyCreditsBG1 from "../assets/buy_credits_bg1.png";
import React from "react";
export default function CreditsModal({children}) {
    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div
                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                    <div
                        className="relative transform overflow-hidden rounded-2xl bg-gradient-to-b from-white to-rose-50 text-left shadow transition-all sm:my-8 sm:w-full sm:max-w-lg border border-stone-300">
                        <div
                            className="bg-gradient-to-b from-white to-rose-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4 bg-no-repeat bg-right"
                            style={{ backgroundImage: `url(${BuyCreditsBG1})` }}>
                                {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}