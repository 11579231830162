import React, { useCallback, useEffect, useState } from 'react';
import CreditsBG from "../assets/credit-bg.png"
import CreditsBG2 from "../assets/credits-bg2.svg"
import CopyIcon from "../assets/copy_icon.svg"
import ArrowLineUpRight from "../assets/ArrowLineUpRight.svg"
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import { useSearchParams } from 'react-router-dom';
import BuyCreditsBG1 from "../assets/buy_credits_bg1.png";
import CloseIcon from "../assets/close-icon.svg"
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../Authentication";
import { TRYON_SERVER_URL } from "../config";
import { setUserData, setUserID } from "../store/AuthActions";
import CreditsModal from '../components/CreditsModal';
import { selectUserData } from '../selectors';
import { CREDIT_VALUE } from '../config';
import axios from 'axios';
import { CASHFREE_PAYMENT_GATEWAY_URL, CASHFREE_MODE } from '../config';
import { load } from "@cashfreepayments/cashfree-js";


export default function Credits(props) {
    const accessToken = useSelector(selectAccessToken)
    const [showToast, setShowToast] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState("");
    const [showBuyCreditsModal, setShowBuyCreditsModal] = React.useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [userCredits, setUserCredits] = useState(0)
    const authenticated = useSelector(selectAuthenticated)
    const { isAuthenticated } = useAuthentication();
    const [showCreditStatus, setShowCreditStatus] = useState(false);
    const userData = useSelector(selectUserData);
    const [numOfCredits, setNumOfCredits] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("This is an error");
    const [showCardError, setShowCardError] = useState(false);
    const [errorCardMessage, setErrorCardMessage] = useState("This is an error");
    const searchParams = useSearchParams()[0];
    const [orderId, setOrderId] = useState(null);
    const [creditStatus, setCreditStatus] = useState(null);
    const [creditAmount, setCreditAmount] = useState(0);
    const [broughtCredits, setBroughtCredits] = useState(0);

    function handleCopyAccessToken() {
        navigator.clipboard.writeText(accessToken)
        setShowToast(true);
        setToastMessage("Access Token Copied!");

        setTimeout(() => {
            setShowToast(false)
            setToastMessage("")
        }, 2500);
    }

    async function fetchUserDetails() {
        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data.id
                dispatch(setUserID(userid));
                const dataWithoutPassword = { ...data, password: undefined }; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
                setUserCredits(dataWithoutPassword.credits);
                setUserDetailsFetched(true)
            } else {
                console.error('Failed to fetch user data');
                setUserDetailsFetched(true)
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserDetailsFetched(true)

        }
    }

    useEffect(() => {
        const order_id = searchParams.get("order_id");

        console.log("order id:", order_id)
        
        if (order_id && accessToken) {
            (async () => {
                try {
                    const response = await fetch(`${TRYON_SERVER_URL}/api/v1/payment/status/?order_id=${order_id}`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        console.log("Fetched Data:", data);

                        if (data?.status) {
                            setCreditStatus(data.status);
                            setOrderId(data.order_id);
                            setCreditAmount(data.amount);
                            setBroughtCredits(data.credits);
                            setShowCreditStatus(true);
                        } else {
                            console.error("Status not found in response:", data);
                        }
                    } else {
                        console.error('Error fetching payment status:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error fetching payment status:', error);
                }
            })();
        }
    }, [searchParams, accessToken, userDetailsFetched]);



    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/signin");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/signin");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    let cashfree;
    var initializeSDK = async function () {
        cashfree = await load({
            mode: CASHFREE_MODE
        });
    }
    initializeSDK();

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication().then(() => {
            });
        }

        if (!userDetailsFetched) {
            fetchUserDetails().then(() => {
            })
        }
    }, []);

    // const handlePlaceOrder = async (formdata) => {
    //     try {
    //         const response = await axios.post(
    //             `${TRYON_SERVER_URL}/api/v1/payment/initiate/`, 
    //             formdata,
    //             {
    //                 headers: {
    //                     'Authorization': `Bearer ${accessToken}`,
    //                     'Content-Type': 'application/json',
    //                 },
    //             }
    //         );

    //         if (response.data.payment_link) {
    //             window.location.href = response.data.payment_link; 
    //         } else {
    //             throw new Error("Payment initiation failed.");
    //         }
    //     } catch (error) {
    //         console.error("Error initiating payment:", error);
    //         throw error;
    //     }
    // };

    const handlePlaceOrder = async (formdata) => {
        try {
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/payment/initiate/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formdata)
            });

            const data = await response.json();
            if (response.ok) {
                const paymentSessionId = data.payment_session_id;

                if (paymentSessionId) {
                    let checkoutOptions = {
                        paymentSessionId: paymentSessionId,
                        redirectTarget: "_self",
                    };
                    await cashfree.checkout(checkoutOptions);
                }
            } else {
                console.error('Error initiating payment:', data.error || 'Unknown error');
            }
        } catch (error) {
            console.error('Error placing order:', error);
        }
    };


    const handleBuyCredits = () => {
        console.log(numOfCredits, mobileNumber)
        try {
            if (!authenticated || !userDetailsFetched) {
                navigate("/signin");
                return;
            }

            if (numOfCredits <= 0) {
                setErrorCardMessage("Please enter a valid number of credits.");
                setShowCardError(true);
                return;
            }

            if ((Math.round(numOfCredits * CREDIT_VALUE * 100) / 100) < 85) {
                setErrorCardMessage("You need to buy at least 100 credits (INR 85).");
                setShowCardError(true);
                return;
            }

            if (!mobileNumber || String(mobileNumber).length !== 10) {
                setErrorCardMessage("Please enter a valid 10-digit mobile number.");
                setShowCardError(true);
                return;
            }

            const formdata = {
                amount: Math.round(numOfCredits * CREDIT_VALUE * 100) / 100,
                mobile: String(mobileNumber),
                credits: numOfCredits
            };

            console.log("Buying credits", formdata);

            handlePlaceOrder(formdata)
                .then(() => {
                    setShowBuyCreditsModal(false);
                })
                .catch((error) => {
                    setErrorCardMessage(error?.response?.data?.message || "Unable to buy credits. Please try again.");
                    setShowCardError(true);
                });

        } catch (error) {
            console.error("Error in handleBuyCredits:", error);
            setErrorCardMessage("An unexpected error occurred. Please try again.");
            setShowCardError(true);
        }
        // finally {
        //     setShowBuyCreditsModal(false);
        // }
    };

    const handleCreditsChange = (event) => {
        // const inputValue = event.target.value;
    
        // // Check if the input is a number and if it contains a decimal point
        // if (!isNaN(inputValue) && !inputValue.includes('.')) {
        //   setNumOfCredits(inputValue);
        // }
        setNumOfCredits(parseInt(event.target.value, 10) || "");
      };

    const handleMobileNumberChange = (event) => {
        // const inputValue = event.target.value;
    
        // // Check if the input is a number and if it contains a decimal point
        // if (!isNaN(inputValue) && !inputValue.includes('.')) {
        //   setMobileNumber(inputValue);
        // }
        setMobileNumber(parseInt(event.target.value, 10) || "");
      };

    return (
        <div className="p-4 relative">
            {authenticated ? (<>
                <div className="bg-gradient-to-b from-stone-100 to-emerald-100 rounded-2xl">
                    <div className="w-full rounded-2xl bg-right p-8 bg-no-repeat"
                        style={{ backgroundImage: `url(${CreditsBG2})` }}>
                        <div className="text-stone-800 text-2xl font-normal font-Mulish">Credits</div>

                        <div className="text-center inline-block mt-6">
                            <div style={{ backgroundImage: `url(${CreditsBG})` }}
                                className="bg-no-repeat bg-contain bg-center px-10 py-6">
                                <div className="text-stone-800 text-2xl font-semibold">{userCredits}</div>
                            </div>

                            <div className="text-green-600 text-xs font-normal font-Mulish">Total Credit Available
                            </div>
                        </div>

                        <div className="flex justify-end items-center">
                            <button type="button" onClick={() => {
                                setShowBuyCreditsModal(true)
                            }}
                                className="cursor-pointer px-6 py-2 bg-gray-900 rounded-[50px] border backdrop-blur-[100px] justify-center items-center gap-[9px] inline-flex">
                                <div className="text-red-100 text-base leading-normal">
                                    Buy Credits
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-b from-stone-100 to-rose-200 rounded-2xl p-8 mt-4">
                    <div className="text-stone-800 text-2xl font-Mulish">Access Token</div>
                    <div
                        className="bg-rose-100 rounded-[56px] border-dashed border border-gray-600 px-4 py-2 mt-10 flex overflow-x-auto justify-between items-center">
                        <div
                            className="text-stone-800 text-base font-semibold">{accessToken.slice(0, 20)}...{accessToken.slice(-20)}
                        </div>

                        <button type="button"
                            className="px-6 py-2 bg-gray-900 rounded-[48px] border backdrop-blur-[100px] inline-flex justify-center items-center gap-2"
                            onClick={handleCopyAccessToken}>
                            <div className="text-red-100 text-base leading-normal">Copy</div>
                            <img src={CopyIcon} alt="copy" className="" />
                        </button>
                    </div>

                    <div className="mt-10 text-center">
                        <div className="text-[#424242] text-base leading-normal">See
                            documentation to know how to use APIs
                        </div>

                        <a type="button"
                            href="https://documenter.getpostman.com/view/31553983/2sA2xpTpoF" target='_blank'
                            className="cursor-pointer px-4 py-2 rounded-[50px] border border-rose-700 backdrop-blur-[100px] justify-center items-center gap-2.5 inline-flex mt-6">
                            <div className="text-rose-700 text-sm leading-normal">See
                                Documentation
                            </div>
                            <img src={ArrowLineUpRight} alt="arrow up right" />
                        </a>
                    </div>
                </div>

                {showToast ? (
                    <div id="toast-bottom-right"
                        className="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow right-5 bottom-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
                        role="alert">
                        <div className="text-sm font-normal">{toastMessage}</div>
                    </div>) : (<></>)}

                {showBuyCreditsModal ? (
                    // <CreditsModal>
                    //     <div className="sm:flex flex-col sm:items-start">
                    //         <div className="flex flex-col w-full text-center sm:text-left">
                    //             <div
                    //                 className="text-neutral-800 text-2xl font-normal font-Mulish">Buy
                    //                 Credits
                    //             </div>
                    //             <div
                    //                 className="text-neutral-800 text-2xl mt-12">Lets
                    //                 level up your Brand, together
                    //             </div>
                    //             <div className="mt-2"><span className="text-stone-800 text-base">contact us to buy credits to use our APIs</span>
                    //             </div>



                    //             <div className="mt-8">
                    //                 <a href="mailto:contact@tryonlabs.ai"
                    //                     className="text-rose-700 text-base">contact@tryonlabs.ai
                    //                 </a>
                    //             </div>
                    //         </div>
                    //     </div>

                    //     <button type="button"
                    //         onClick={() => {
                    //             setShowBuyCreditsModal(false)
                    //         }}
                    //         className="cursor-pointer w-8 h-8 right-2 top-2 absolute bg-white rounded-full border border-stone-300">
                    //         <img src={CloseIcon} alt="close" />
                    //     </button>
                    // </CreditsModal>
                    <CreditsModal>
                        <div className="sm:flex flex-col sm:items-start">
                            <div className="flex flex-col w-full text-center sm:text-left">
                                <div
                                    className="text-neutral-800 text-2xl font-normal font-Mulish">Buy
                                    Credits
                                </div>

                                <div className="mt-6">
                                    <label className="block text-neutral-700 text-sm font-medium">
                                        Enter Number Of Credits *
                                    </label>
                                    <input
                                        type="number"
                                        value={numOfCredits}
                                        min="100" 
                                        step="1"
                                        onChange={handleCreditsChange}
                                        // defaultValue="1000"
                                        className="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rose-500 focus:ring-rose-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mt-6">
                                    <label className="block text-neutral-700 text-sm font-medium">
                                        Enter Mobile Number *
                                    </label>
                                    <input
                                        type="number"
                                        value={mobileNumber}
                                        min="1"
                                        step="1"
                                        onChange={handleMobileNumberChange}
                                        // defaultValue="1000"
                                        className="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rose-500 focus:ring-rose-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mt-4 w-full">
                                    <p className="text-neutral-700 text-sm">
                                        Amount: <span className="font-bold">INR {Math.round(numOfCredits * CREDIT_VALUE * 100) / 100}</span>
                                    </p>
                                </div>

                            </div>
                            <div className=" px-4 py-3 sm:flex sm:flex-row-reverse w-full">
                                <button
                                    type="button"
                                    onClick={handleBuyCredits}
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-rose-600 text-base font-medium text-white hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 sm:ml-3 sm:w-auto sm:text-sm">
                                    Proceed to Pay
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowBuyCreditsModal(false)}
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm">
                                    Cancel
                                </button>
                            </div>
                            <div className="flex justify-start items-center gap-4 py-3">
                            <a href="https://www.levit8labs.in/refund_and_cancellation" target="_blank" className="text-center text-sm text-gray-500 underline">
                                Refund & Cancellations
                            </a>
                            
                            <a href="https://www.levit8labs.in/contact_us" target="_blank" className="text-center text-sm text-gray-500 underline">
                                Contact Us
                            </a>
                                </div>
                        </div>

                        <button type="button"
                            onClick={() => {
                                setShowBuyCreditsModal(false)
                            }}
                            className="cursor-pointer w-8 h-8 right-2 top-2 absolute bg-white rounded-full border border-stone-300">
                            <img src={CloseIcon} alt="close" />
                        </button>
                        {showCardError && (
                            <p className="mt-4 text-sm text-red-600 dark:text-red-400 text-center">
                                {errorCardMessage}
                            </p>
                        )}
                    </CreditsModal>
                ) : (<></>)}

                {showCreditStatus ? (
                    <CreditsModal>
                        <div className="sm:flex flex-col sm:items-start">
                            <div className="flex flex-col w-full text-center sm:text-left">
                                <div
                                    className="text-neutral-800 text-2xl font-normal font-Mulish">Payment Status!
                                </div>
                            </div>
                        </div>
                        <span className="flex h-full my-8 text-neutral-700 text-sm font-medium ">
                            {`Payment of INR ${creditAmount} for ${broughtCredits} credits with order id ${orderId} is ${creditStatus}!`}
                        </span>
                        <div className=" px-4 py-3 sm:flex sm:flex-row-reverse w-full">
                            <button
                                onClick={() => {
                                    setShowCreditStatus(false)
                                    setOrderId(null)
                                    setCreditStatus(null)
                                    setMobileNumber("")
                                    setNumOfCredits("")
                                    setShowBuyCreditsModal(true)
                                }}
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-rose-600 text-base font-medium text-white hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 sm:ml-3 sm:w-auto sm:text-sm">
                                Buy More
                            </button>

                        </div>
                        <button type="button"
                            onClick={() => {
                                setShowCreditStatus(false)
                                setOrderId(null)
                                setCreditStatus(null)
                                setShowBuyCreditsModal(false)
                            }}
                            className="cursor-pointer w-8 h-8 right-2 top-2 absolute bg-white rounded-full border border-stone-300">
                            <img src={CloseIcon} alt="close" />
                        </button>
                    </CreditsModal>
                )
                    : (<></>)}

                {showError && (
                    <p className="mt-4 text-sm text-red-600 dark:text-red-400 text-center">
                        {errorMessage}
                    </p>
                )}
            </>
            ) : (<></>)}
        </div>
    )
}
