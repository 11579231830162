import React from 'react';

const ThreeDotSpinner = () => {
    return (
        <div className="flex justify-center items-center space-x-1 animate-pulse">
            <div className="w-2 h-2 bg-[#BE3144] rounded-full animate-wave1"></div>
            <div className="w-2 h-2 bg-[#BE3144] rounded-full animate-wave1 delay-100"></div>
            <div className="w-2 h-2 bg-[#BE3144] rounded-full animate-wave1 delay-200"></div>
        </div>
    );
};

export default ThreeDotSpinner;