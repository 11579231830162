import React, {useState} from 'react';
import { AsyncImage } from "loadable-image";
import downloadIcon from "../assets/download.svg";
import enalargeIcon from "../assets/expandicon.svg";
import ImageViewer from './ImageViewer';
import ThreeDotSpinner from './ThreeDotSpinner';

const PictureCard = ({ experiment, imageUrl, label, index, TRYON_SERVER_URL, loading}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    };

    const handleOpenModal = (imageUrl) => {
        setShowModal(true);
        setModalImage(imageUrl);
    };

    const handleDownload = async (imageUrl) => {
        let headers = {};

        const response = await fetch(imageUrl, {
            headers: headers
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Result.png'; 
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div
            key={experiment.id}
            className={`group cursor-pointer relative flex items-center justify-center 
                border-2 border-solid rounded-xl transition-all duration-300 ease-in-out
                hover:border-[#BE3144] hover:shadow-lg hover:shadow-[#BE3144]/10
                aspect-[3/4] overflow-hidden border-[#F8EEEE]`}
        >
            <div className="relative w-full h-full">
                <AsyncImage
                    loader={
                        <div className="flex h-full w-full bg-gray-50 items-center justify-center">
                            <ThreeDotSpinner />
                        </div>
                    }
                    error={
                        <div className="flex h-full w-full bg-gray-100 items-center justify-center text-gray-500">
                            <span>Failed to load image</span>
                        </div>
                    }
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transition: 'transform 0.3s ease-in-out',
                    }}
                    className="group-hover:scale-105"
                    alt={`garment image ${index + 1}`}
                    src={`${TRYON_SERVER_URL}/${imageUrl}`}
                />
            </div>

            {/* Action buttons */}
            <div className="absolute top-3 w-full text-black opacity-0 group-hover:opacity-100 
                transition-opacity duration-300 ease-in-out flex justify-between items-center px-4 z-10">
                <button 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(`${TRYON_SERVER_URL}/${imageUrl}`);
                    }}
                    className="flex items-center justify-center p-2 bg-white/90 rounded-full shadow-lg
                        hover:bg-white hover:scale-110 transition-all duration-200"
                >
                    <img
                        src={downloadIcon}
                        alt="Download"
                        className="w-4 h-4"
                    />
                </button>

                <button 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOpenModal(`${TRYON_SERVER_URL}/${imageUrl}`);
                    }}
                    className="flex items-center justify-center p-2 bg-white/90 rounded-full shadow-lg
                        hover:bg-white hover:scale-110 transition-all duration-200"
                >
                    <img
                        src={enalargeIcon}
                        alt="Enlarge"
                        className="w-3 h-3"
                    />
                </button>
            </div>

            {/* Label */}
            <div className="absolute bottom-3 right-3 text-black bg-white/90 backdrop-blur-md 
                shadow-lg border border-white/20 px-3 py-1.5 
                flex items-center justify-center rounded-full 
                font-medium text-sm tracking-wide
                transform transition-all duration-200 hover:scale-105
                opacity-90 hover:opacity-100">
                <span>{label}</span>
            </div>

            {showModal && (
                <ImageViewer
                    image={modalImage}
                    onClose={handleCloseModal}
                />
            )}

            {loading && (
                <div className="absolute inset-0 backdrop-blur-sm bg-white/50 
                    flex items-center justify-center w-full h-full z-20 rounded-xl">
                    <ThreeDotSpinner />
                </div>
            )}
        </div>
    );
};

export default PictureCard;
