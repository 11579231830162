import React, {useEffect, useRef, useState} from "react";
import Sidebar from "../../components/Sidebar";
import landingbg2 from "../../assets/bgGradient2.svg";
import Navbar2 from "../../components/Navbar2";
import {useSelector} from "react-redux";
import {selectAuthenticated} from "../../selectors";
import Experiments from "./Experiments";
import Users from "./Users";
import {useNavigate} from "react-router-dom";
import Dashboard from "./Dashboard";

export default function Home(props){
    const sidebarRef = useRef(null);
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState("dashboard");
    const [options, setOptions] = React.useState({
        "dashboard": true,
        "users": false,
        "experiments": false,
    });

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const authenticated = useSelector(selectAuthenticated);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!authenticated) {
            navigate("/signin", {state: {path: "/admin"}});
        }else{
            setLoading(false);
        }
    }, [authenticated,]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const closeSideBar = () => {
        setSidebarOpen(false);
    }

    const handleClickOutside = (event) => {
        console.log("clicked outside", sidebarRef.current)
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            console.log("clicked outside sidebar",isMobile)
            if(isMobile){
                toggleSidebar(false);
            }
        }
    };

    const toggleSidebar = () => {
        setSidebarOpen(choice => !choice);
    };

    function handleOptionSelect(option, options) {
        console.log("Option selected:", option)
        setSelectedOption(option)
        setOptions(options)
    }

    function renderDashboard() {
        console.log("Selected option:", selectedOption)
        if (selectedOption === "dashboard") {
            return (<Dashboard/>)
        } else if (selectedOption === "users") {
            return (<Users/>)
        }
        else if (selectedOption === "experiments") {
            return (<Experiments/>)
        }
    }

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    return (<div>
        <div className="h-screen" style={{ backgroundImage: `url(${landingbg2})`, backgroundSize: 'cover'}}>
           {authenticated ?renderDashboard(): (
                <div className="flex items-center justify-center h-full w-full">
                    <iframe src="https://lottie.host/embed/7fa96264-0a04-4f64-852f-9d97faff2505/6DLlXWfhJH.json"></iframe>
                </div>)
            }
        </div>
    </div>)
}