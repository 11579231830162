import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectAccessToken, selectAuthenticated, selectUserData} from "../selectors";
import InfoCard from "../components/InfoCard";
import { boot, update } from "@intercom/messenger-js-sdk";
import Intercom from '@intercom/messenger-js-sdk';
import closeIcon from "../assets/close.svg";
import Common from "../components/Onboarding/Common";
import { TRYON_SERVER_URL } from "../config";
import axios from "axios";
import { Button } from "flowbite-react";

export default function Playground(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const navigate = useNavigate();
    const sidebarRef = useRef(null);
    const authenticated = useSelector(selectAuthenticated);
    const user = useSelector(selectUserData);
    const [showBasicInfoModal, setShowBasicInfoModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorStatus, setErrorStatus] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const accessToken = useSelector(selectAccessToken)
    const [showBusinessTypeModal, setShowBusinessTypeModal] = useState(true);
    const [showProductCategoryModal, setShowProductCategoryModal] = useState(false);
    const [showECommercePlatformModal, setShowECommercePlatformModal] = useState(false);
    const [showLookingForModal, setShowLookingForModal] = useState(false);
    const [showBusinessGoalModal, setShowBusinessGoalModal] = useState(false);

    const [userOnboardingDataFetched, setUserOnboardingDataFetched] = useState(false);
    const [showThankYouModal, setShowThankYouModal] = useState(false);

    const businessTypes = ["E-Commerce Company", "D2C Brand", "Fashion Designer", "App Developer", "Other(Specify)"];
    const businessGoals = ["Enhance User Experience", "Improve Design Visualization", "Increase Conversion Rates", "Reduce Return Rates", "Streamline Design-to-Production Process", "Improve Catalog Management Efficiency", "Stay Competitive in the Market", "Other(Specify)"];
    const productCategories = ["Clothing", "Shoes", "Jewelry", "Accessories", "Eyewear", "Makeup", "Home Furnishings", "Other(Specify)"];
    const eCommercePlatforms = ["Shopify", "Magento", "WooCommerce", "BigCommerce", "Other(Specify)"];
    const lookingFor = ["Virtual Try-On", "Model Swap", "Outfit Generation", "Outfit Caption", "Other(Specify)"];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            }else{
                setSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            if (isMobile) {
                toggleSidebar(false);
            }
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    useEffect(() => {
        if(authenticated){
            if(!userOnboardingDataFetched){
                fetchUserOnboardingData().then(() => {
                });
            }
        }
    }, [authenticated, user, userOnboardingDataFetched]);

    const toggleSidebar = () => {
        setSidebarOpen(choice => !choice);
    };

    const showErrorModal = (status, message) => {
        setShowError(true);
        setErrorStatus(status);
        setErrorMessage(message);
    }

    useEffect(() => {
        console.log("Intercom app id:", process.env.REACT_APP_INTERCOM_APP_ID)
        
        Intercom({app_id: process.env.REACT_APP_INTERCOM_APP_ID});

        if (authenticated && user) {
            const parsedUser = JSON.parse(user);
            update({
                user_id: parsedUser?.id,
                name: parsedUser?.first_name,
                email: parsedUser?.email,
                created_at: Date.now(),
              });
        }else{
            console.log('not authenticated')
            boot({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
              });
        }
    }, [authenticated, user]);


    const handleErrorClose = () => {
        setShowError(false);
    }

    const handleContinue = (option, value) => {
        console.log("Continue", option, value);
        if(option === "Business Type"){
            setShowBusinessTypeModal(false);
            setShowBusinessGoalModal(true);
        }else if(option === "Business Goal"){
            setShowBusinessGoalModal(false);
            setShowProductCategoryModal(true);  
        }else if(option === "Product Category"){
            setShowProductCategoryModal(false);
            setShowECommercePlatformModal(true);
        }else if(option === "E-Commerce Platform"){
            setShowECommercePlatformModal(false);
            setShowLookingForModal(true);
        }else if(option === "Looking For"){
            setShowLookingForModal(false);
            setShowThankYouModal(true);
        }

        if(option === "Business Type"){ 
            updateUserOnboardingData({"onboarding_step": "business_type", 
                "onboarding_value": value});
        }else if(option === "Business Goal"){
            updateUserOnboardingData({"onboarding_step": "business_goal", 
                "onboarding_value": value});
        }else if(option === "Product Category"){
            updateUserOnboardingData({"onboarding_step": "product_category", 
                "onboarding_value": value});
        }else if(option === "E-Commerce Platform"){
            updateUserOnboardingData({"onboarding_step": "ecommerce_platform", 
                "onboarding_value": value});
        }else if(option === "Looking For"){
            updateUserOnboardingData({"onboarding_step": "looking_for", 
                "onboarding_value": value});
        }
    }

    const handleSkip = (option) => {
        console.log("Skip", option);
        setShowBasicInfoModal(false);
        localStorage.setItem("OnboardingSkipped", true);
    }

    async function fetchUserOnboardingData() {
        try {
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/onboarding/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                if(data.length > 0 || localStorage.getItem("OnboardingSkipped") === "true"){
                    setShowBasicInfoModal(false);
                    setUserOnboardingDataFetched(true);
                }else{
                    setShowBasicInfoModal(true);
                    setUserOnboardingDataFetched(true);
                }
            } else {
                console.error('Failed to fetch user data');
                setUserOnboardingDataFetched(false);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserOnboardingDataFetched(false);
        }
    }

    async function updateUserOnboardingData(data){
        try{
            axios.post(`${TRYON_SERVER_URL}/api/v1/user/onboarding/`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                console.log("Update User Onboarding Data Response:", response);
                // alert("User onboarding data updated successfully");
                setShowError(false);
                setErrorStatus("");
                setErrorMessage("");
            }).catch((error) => {
                console.error('Error updating user onboarding data:', error);
                //alert("Failed to update user onboarding data");
                setShowError(true);
                setErrorStatus("Error");
                setErrorMessage("Error updating user onboarding data");
            });
        } catch (error) {
            console.error('Error updating user onboarding data:', error);
            // alert("Error updating user onboarding data");
            setShowError(true);
            setErrorStatus("Error");
            setErrorMessage("Error updating user onboarding data");
        }
    }

    return (
        <div className="p-4 lg:grid lg:grid-cols-3 gap-4">

            <InfoCard title="TryOn AI" description="Virtual Try-On lets you try a garment in different poses. It helps buyers make a conscious decision before making a purpose." onClick= {() => {navigate("/tryonai")}}/>

            <InfoCard title="Model Swap AI" description="Replace a human model with an AI-generated model, ensuring the garment stays intact and unchanged." onClick= {() => {navigate("/modelswapai")}}/>
            
            <InfoCard title="Outfit Generation AI" description="Use AI to design outfits for any occasion based on specific prompts. It is ideal for fashion tech developers and e-commerce companies!" onClick= {() => {navigate("/outfitai")}}/>

            <InfoCard title="Outfit Caption AI" description="Use AI to generate captions for outfits in plain text or JSON format. This AI can identify details like color, pattern, style, material, sleeve style, hemline, waistline, and neckline.!" onClick= {() => {navigate("/outfitcaptionai")}}/>

            {/* Basic Info Modal */}
            {showBasicInfoModal && authenticated ? (
            <div className="w-full h-full lg:h-screen overflow-y-auto bg-gradient-to-b from-rose-100/50 to-red-50/50 fixed top-0 left-0 z-50">
            
                <div className="relative max-w-xl mx-4 lg:mx-auto h-auto my-10 text-left bg-white rounded-lg p-6 shadow-lg">
                    <button 
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowBasicInfoModal(false);
                        }}
                        className="absolute top-3 right-3 items-center justify-center p-2 bg-white/90 rounded-full shadow-lg
                            hover:bg-white/80 hover:scale-110 transition-all duration-200 shadow-sm border border-gray-200 dark:border-gray-700"
                    >
                        <img
                            src={closeIcon}
                            alt="Close"
                            className="w-3 h-3"
                        />
                    </button>

                    <p className="text-2xl">Welcome to TryOn AI Playground!</p>

                    {showThankYouModal ? (
                        <div className="text-xl mt-4 text-center">Thanks for your time! Enjoy the playground!</div>
                    ):(
                        <div className="text-sm mt-4">Tell us a bit about yourself. This will help us tailor our services to your needs.</div>
                    )}

                    {showBusinessTypeModal && (
                        <Common title="Business Type" multipleSelect={false} options={businessTypes} handleContinue={handleContinue} handleSkip={handleSkip} showErrorModal={showErrorModal} />
                    )}

                    {showBusinessGoalModal && ( 
                        <Common title="Business Goal" multipleSelect={true} options={businessGoals} handleContinue={handleContinue} handleSkip={handleSkip} showErrorModal={showErrorModal} />
                    )}

                    {showProductCategoryModal && (
                        <Common title="Product Category" multipleSelect={true} options={productCategories} handleContinue={handleContinue} handleSkip={handleSkip} showErrorModal={showErrorModal} />
                    )}

                    {showECommercePlatformModal && (
                        <Common title="E-Commerce Platform" multipleSelect={true} options={eCommercePlatforms} handleContinue={handleContinue} handleSkip={handleSkip} showErrorModal={showErrorModal} />
                    )}

                    {showLookingForModal && (
                        <Common title="Looking For" multipleSelect={true} options={lookingFor} handleContinue={handleContinue} handleSkip={handleSkip} showErrorModal={showErrorModal} />
                    )}

                    {showThankYouModal && (
                        <div className="flex flex-col items-center justify-center">
                            <Button className="mt-4 bg-gradient-to-r from-red-500 to-rose-600 hover:bg-gradient-to-r hover:from-red-600 hover:to-rose-700 rounded-lg text-white"
                            onClick={() => {
                                setShowThankYouModal(false);
                                setShowBasicInfoModal(false);
                            }}
                            >
                                Open Playground
                            </Button>
                    </div>
                    
                    )}
                </div>

                {showError && (
                <div id="toast-default" class="fixed bottom-5 left-1/2 transform -translate-x-1/2 flex items-center w-full max-w-lg p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                    <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                        </svg>
                        <span class="sr-only">Error icon</span>
                    </div>
                    <div class="flex flex-col">
                    <div class="ms-3 text-base font-normal text-red-500">{errorStatus}</div>
                    <div class="ms-3 text-sm font-normal text-red-400">{errorMessage}</div>
                    </div>
                    <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-default" aria-label="Close" onClick={handleErrorClose}>
                        <span class="sr-only">Close</span>
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </button>
                </div>
                )}
            </div>
            ):(<div></div>)}
        </div>
    )
}