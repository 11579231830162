import { useState } from 'react';
import { Button } from 'flowbite-react';

export default function Common({title, options, handleContinue, handleSkip, showErrorModal, multipleSelect}) {
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValueInput, setSelectedValueInput] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);

    const handleContinueLocal = () => {

        if(multipleSelect){
            if (selectedValues.length === 0){
                showErrorModal("Error", `Please select at least one ${title}`);
            } else if(selectedValues.length === 1 && selectedValues.includes('Other(Specify)')) {
                if(selectedValueInput === ""){
                    showErrorModal("Error", `Please specify your ${title}`);
                } else {
                    handleContinue(title, selectedValueInput);
                }
            } else {
                handleContinue(title, selectedValues.join(', '));
            }
        } else if (selectedValue === ""){
            showErrorModal("Error", `Please select a ${title}`);
        } else if (selectedValue === 'Other(Specify)' && selectedValueInput === ""){
            showErrorModal("Error", `Please specify your ${title}`);
        } else {
            if(selectedValue === 'Other(Specify)'){ 
                handleContinue(title, selectedValueInput);
            } else {
                handleContinue(title, selectedValue);
            }
        }
    }

    return (
        <div>
            <div className="mt-6 mb-4 text-base">
                {(() => {
                    const titlesMap = {
                        "Business Type": "What type of business are you?",
                        "Business Goal": "What is your business goal?",
                        "Product Category": "What category of products do you sell?",
                        "E-Commerce Platform": "What e-commerce platform do you use?",
                        "Looking For": "What are you looking for?",
                    };

                    return titlesMap[title] || ""; // Return the corresponding message or an empty string if not found
                })()}
            </div>

            <ul className="flex flex-col w-full lg:w-3/4 mx-auto rounded-lg border border-gray-200 dark:border-gray-700 justify-start h-full text-sm font-medium" id="option-tab" data-tabs-toggle="#option-tab-content" role="tablist">
                        
                {options.map((optionValue, index) => (
                    <li key={index} className={`w-full h-full px-4 py-1 flex items-center justify-center flex-col 
                        ${index === 0 ? 'rounded-t-lg' : ''} ${index === options.length - 1 ? 'rounded-b-lg' : ''}
                    ${optionValue === selectedValue || selectedValues.includes(optionValue) ? 'bg-gradient-to-r from-red-600 to-rose-500 text-white' : 'bg-white text-gray-800'}`} role="presentation">
                    <button
                        className="w-full h-full px-1 py-2"
                        id="text2-tab"
                        data-tabs-target="#text"
                        type="button"
                        role="tab"
                        aria-controls="text"
                        aria-selected={optionValue === selectedValue}
                        onClick={() => {
                            if(multipleSelect){
                                const updatedValues = (() => {
                                    console.log("selected values before", selectedValues);
                                    let newValues = [...selectedValues];

                                    // remove option value
                                    if(newValues.includes(optionValue)){
                                        console.log("remove other specify3");
                                        newValues = newValues.filter(value => value !== optionValue);
                                    }else{
                                        // add option value
                                        newValues.push(optionValue);
                                    }

                                    if(optionValue === 'Other(Specify)'){
                                        // remove all other values
                                        newValues = newValues.filter(value => value === 'Other(Specify)');
                                    }else{
                                        console.log("remove other specify3");
                                        newValues = newValues.filter(value => value !== 'Other(Specify)');
                                    }

                                    // if(optionValue === 'Other(Specify)'){
                                    //     // Remove 'Other(Specify)' if it is included
                                    //     if (newValues.includes('Other(Specify)')) {
                                    //         console.log("remove other specify");
                                    //         newValues = newValues.filter(value => value !== 'Other(Specify)');
                                    //     }
                                    // }else{
                                    //     if(newValues.includes('Other(Specify)')){
                                    //         console.log("remove other specify2");
                                    //         newValues = newValues.filter(value => value !== 'Other(Specify)');
                                    //     }
                                    // }

                                    // Add or remove the optionValue
                                    // if (!newValues.includes(optionValue)) {
                                    //     console.log("add option value", optionValue);
                                    //     newValues.push(optionValue); // Add the optionValue
                                    // } else {
                                    //     console.log("remove option value", optionValue);
                                    //     newValues = newValues.filter(value => value !== optionValue); // Remove the optionValue
                                    // }

                                    return newValues; // Return the updated array
                                })();

                                console.log("updated values after", updatedValues);
                                setSelectedValues(updatedValues); // Update state once with the new array
                            } else if(optionValue === 'Other(Specify)'){
                                setSelectedValue(optionValue);
                                setSelectedValueInput('');
                            } else {
                                setSelectedValue(optionValue);
                                setSelectedValueInput('');
                            }
                        }}
                    >
                        <div className="flex justify-center gap-2 items-center">
                            {optionValue}
                            {selectedValues.includes(optionValue) || selectedValue === optionValue ? (
                                <svg className="ml-2 w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            ) : (
                                <></>
                            )}
                        </div>
                    </button>
                </li>
                ))}
            </ul>

            {selectedValue === 'Other(Specify)' || selectedValues.includes('Other(Specify)')? (
            <div>
                <input
                    type="text"
                    value={selectedValueInput}
                    onChange={(e) => {
                        setSelectedValueInput(e.target.value);
                    }}
                    placeholder={`Specify your ${title}`}
                    className="mt-2 w-full py-3 lg:w-3/4 mx-auto block w-full rounded-md border border-gray-300 shadow-sm focus:border-rose-500 focus:ring-rose-500 sm:text-sm"
                />
            </div>
            ):(<div></div>)}

            <div className="flex justify-end gap-2 mt-4">
                <Button className="bg-gradient-to-r from-red-500 to-rose-600 hover:bg-gradient-to-r hover:from-red-600 hover:to-rose-700 rounded-lg text-white"
                onClick={() => {
                    handleContinueLocal();
                }}
                >{title === "Looking For" ? "Finish" : "Continue"}</Button>
                {title !== "Looking For" ? (
                <Button color="gray" className="focus:outline-none focus:ring-0" onClick={(e) => {e.stopPropagation(); handleSkip();}}>
                    Skip
                </Button>):(<></>)}
            </div> 
        </div>
    )
}