import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Signin from "./pages/Signin"
import Signup from "./pages/Signup"
import Playground from "./pages/Playground"
import Home from "./pages/admin/Home";
import Preview from "./pages/Preview";
import ModelSwapAI from "./pages/ModelSwapAI";
import Navbar2 from "./components/Navbar2";
import React, {useEffect, useRef, useState} from "react";
import Sidebar from "./components/Sidebar";
import TryonAI from "./pages/TryonAI";
import Credits from "./pages/Credits";
import Profile from "./pages/Profile";
import OutfitAI from "./pages/OutfitAI";
import HumanModelGeneration from "./pages/HumanModelGeneration"
import Verification from "./pages/Verification";
import { useMediaQuery } from "react-responsive";
import OutfitCaptionAI from "./pages/OutfitCaptionAI";

const App = () => {
    const location = useLocation();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [currentOption, setCurrentOption] = useState("/");
    const [options, setOptions] = React.useState({
        "/tryonai": false,
        // "/aimodel": false,
        "/modelswapai": false,
        "/outfitai": false,
        "/outfitcaptionai": false,
        "/credits": false,
        "/profile": false,
    });
    const sidebarRef = useRef(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

    useEffect(() => {
        let option = location.pathname;

        console.log("Pathname:", option)
        setCurrentOption(option)

        Object.keys(options).forEach((key) => {
            if (key === option) {
                options[key] = true;
            } else {
                options[key] = false;
            }
        })
        console.log("Updated options:", options)
        setOptions(options)
        
        if(isMobile){
            closeSideBar()
        }else{
            openSideBar()
        }
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(choice => !choice);
    };

    function handleOptionSelect(option, options) {
        console.log("Option selected:", option)
        setOptions(options)
        navigateToPage(option)
        if(isMobile){
            closeSideBar()
        }
    }

    const navigateToPage = (selectedOption) => {
        if (currentOption === selectedOption) {
            console.log("You clicked on the same option:", currentOption)
        } else {
            setCurrentOption(selectedOption)
            navigate(selectedOption)
        }
    }

    const closeSideBar = () => {
        setSidebarOpen(false);
    }

    const openSideBar = () => {
        setSidebarOpen(true);
    }

    return (
        <>
            {location.pathname === "/signin" || location.pathname === "/signup" || location.pathname === "/verify"? (<></>):(
            <Sidebar ref={sidebarRef} handleOptionSelect={handleOptionSelect} isSidebarOpen={isSidebarOpen}
                     closeSideBar={closeSideBar} options={options}/>)}

            <div className={location.pathname==="/signin" || location.pathname === "/signup" || location.pathname === "/verify" ?"md:ml-0":"md:ml-64"}>
            {location.pathname === "/signin" || location.pathname === "/signup" || location.pathname === "/verify"? (<></>):(
                <Navbar2 toggleSidebar={toggleSidebar} handleOptionSelect={handleOptionSelect} options={options}/>)}

                <Routes>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/signin" element={<Signin/>}/>
                    <Route path="/" element={<Playground/>}/>
                    <Route path="/tryonai" element={<TryonAI/>}/>
                    <Route path="/aimodel" element={<HumanModelGeneration/>}/>
                    <Route path="/modelswapai" element={<ModelSwapAI/>}/>
                    <Route path="/credits" element={<Credits/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/admin" element={<Home/>}/>
                    <Route path="/verify" element={<Verification/>}/>
                    <Route path="/preview/:garment_url/:model_url/:tryon_url" element={<Preview/>}/>
                    <Route path="/outfitai" element={<OutfitAI/>}/>
                    <Route path="/outfitcaptionai" element={<OutfitCaptionAI/>}/>
                </Routes>
            </div>
        </>
    );
};

export default App;
