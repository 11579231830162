import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import errorIcon from "../assets/errorIcon.svg";
import ErrorMessage from "../components/ErrorMessage";
import GradientFont from "../components/GradientFont";
import PictureGrid from "../components/PictureGrid";
import NoModels from "../assets/NoModels.svg";
import { TRYON_SERVER_URL } from "../config";
import { selectAccessToken, selectAuthenticated } from "../selectors";

export default function OutfitAI(props) {
    const [prompt, setPrompt] = useState("")
    const accessToken = useSelector(selectAccessToken) || null;
    const authenticated = useSelector(selectAuthenticated);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [errorCode, setErrorCode] = useState(null);

    const [experiments, setExperiments] = useState([]);
    const [experimentsFetched, setExperimentsFetched] = useState(false);
    const [fetchingExperiments, setFetchingExperiments] = useState({});
    const [advancedOptions, setAdvancedOptions] = useState(false);
    const navigate = useNavigate();

    const [seed, setSeed] = useState(-1);
    const [guidanceScale, setGuidanceScale] = useState(7.5);
    const [strength, setStrength] = useState(0.99);
    const [inferenceSteps, setInferenceSteps] = useState(20);
    const [imageResolution, setImageResolution] = useState('256x256');
    const [numResults, setNumResults] = useState(3);

    const handleSeedChange = (e) => setSeed(Number(e.target.value));
    const handleGuidanceScaleChange = (e) => setGuidanceScale(Number(e.target.value));
    const handleStrengthChange = (e) => setStrength(Number(e.target.value));
    const handleInferenceStepsChange = (e) => setInferenceSteps(Number(e.target.value));
    const handleImageResolution = (e) => {
        if(e.target.value === "512x512" ){
            setNumResults(2)
        }else if(e.target.value === "1024x1024"){
            setNumResults(1)
        }else{
            setNumResults(3)
        }
        setImageResolution(e.target.value)
    };

    const handleNumResultsChange = (e) => {
        setNumResults(parseInt(e.target.value, 10) || 1);
    };

    useEffect(() => {
        if (!experimentsFetched) {
            console.log("fetching experiments");
            fetchExperiments().then(() => {
            })
        }

        experiments.map((experiment) => {
            if (experiment?.status === "pending" || experiment?.status === "running") {
                if (!fetchingExperiments[experiment.id]) {
                    console.log("setTimeout", experiment.id)
                    setTimeout(fetchExperiment, 2000, experiment);
                }
            }
        })
    }, [experiments, fetchingExperiments, experimentsFetched]);

    const handleErrorClose = () => {
        setError(false);
        setErrorCode(null);
        setErrorMessage("");
        setErrorStatus("");
    }

    useEffect(() => {
        console.log("Experiment updated:", experiments);
    }, [experiments]);

    const handleLoginRedirect = () => {
        navigate("/signin");
    }


    const createExperiment = async () => {
        if (!authenticated){
            setErrorStatus("Login Required");
            setErrorMessage("The user is not logged in. Please log in and try again!");
            setErrorCode(401);
            setError(true);
            return;
        }
        try {
            let headers = {};
            if (accessToken) {
                headers = {
                    'Authorization': `Bearer ${accessToken}`
                };
            }

            console.log("updating params", guidanceScale, strength, inferenceSteps, seed, numResults, imageResolution);

            // create an experiment
            const experimentData = new FormData();
            experimentData.append("action", "generate_outfit");
            experimentData.append("params", JSON.stringify({
                prompt: prompt, 
                guidance_scale: guidanceScale,
                strength: strength, 
                num_inference_steps: inferenceSteps, 
                num_images_per_prompt: numResults, 
                seed: seed,
                width: imageResolution.split('x')[0],
                height: imageResolution.split('x')[1]
            }));

            console.log("Experiment data:", experimentData);

            const response2 = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/`, {
                method: 'POST',
                headers: headers,
                body: experimentData,
            });

            if (!response2.ok) {
                if (response2.status === 401) {
                    throw new Error("User not logged in. Please Log in and try Again");
                } else {
                    throw new Error(JSON.stringify(await response2.json()));
                }
            }

            const data2 = await response2.json();
            console.log("Experiment created successfully", data2);

            let experiments1 = [...[data2.experiment], ...experiments]

            setExperiments(experiments1)

            setPrompt("")
        } catch (error) {
            console.error("Error during try on:", error.message);
            if (isJSON(error?.message)) {
                let errorData = JSON.parse(error?.message);
                if (errorData?.image && Array.isArray(errorData?.image) && errorData?.image?.length > 0) {
                    setErrorMessage(errorData.image[0]);
                } else if (errorData?.message && Array.isArray(errorData?.message) && errorData?.message?.length > 0) {
                    setErrorMessage(errorData?.message[0]);
                } else {
                    setErrorMessage('Something went wrong! Please try again later');
                }
                // setErrorMessage(errorData[0]);
                setErrorStatus(errorData?.status);
                setError(true);
            } else {
                setErrorStatus("Failure!")
                setErrorMessage(error?.message);
                setError(true);
            }
        }
    }

    const fetchExperiments = async () => {
        // Fetch user results if authenticated or accessToken is available
        if (authenticated || accessToken) {
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/?aimodel=outfit_ai&action=generate_outfit&access=private`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                let res = await response.json();
                console.log("Fetched experiments:", res);
                setExperiments(res);
                setExperimentsFetched(true);
            } else {
                console.error('Failed to fetch model swap experiments');
            }
        } else {
            // setErrorMessage("User not logged in. Please Log in and try Again");
            // setErrorStatus("Failure!");
            // setErrorCode(401);
            // setError(true);
            console.error("User not logged in. Please Log in and try Again");
        }
    }

    //Check if the response is JSON
    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    async function handleGenerateClick(e) {
        console.log("Generate button clicked")
        if (prompt !== "") {
            createExperiment().then(() => {
            })
        } else {
            setErrorMessage("Please provide a prompt to trigger the outfit generation!");
            setErrorStatus("Failure!");
            setError(true);
            setErrorCode(400);
        }

        return false;
    }

    function handlePromptChange(e) {
        setPrompt(e.target.value)
    }

    const toggleAdvancedOptions = () => {
        setAdvancedOptions((prev) => !prev);
    }

    const fetchExperiment = async (experiment) => {
        if (!fetchingExperiments[experiment.id]) {
            let fetchingExperiments1 = { ...fetchingExperiments }; 
            fetchingExperiments1[experiment.id] = true;
            setFetchingExperiments(fetchingExperiments1);

            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/${experiment.id}/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                let res = await response.json();
                let fetchedExperiment = res.experiment;

                console.log("Fetched experiment:", fetchedExperiment);

                if (fetchedExperiment.status === 'completed' || fetchedExperiment.status === 'failed') {
                    console.log("Final status reached:", fetchedExperiment.status);

                    setExperiments((prevExperiments) =>
                        prevExperiments.map((experiment1) =>
                            experiment1.id === fetchedExperiment.id ? { ...experiment1, ...fetchedExperiment } : experiment1
                        )
                    );
                } else if (fetchedExperiment.status === 'pending' || fetchedExperiment.status === 'running') {
                    console.log("Experiment is still running, polling again...");
                    setTimeout(() => fetchExperiment(experiment), 10000);
                }
            } else {
                console.error('Failed to fetch model swap experiment');
            }

            fetchingExperiments1[experiment.id] = false;
            setFetchingExperiments(fetchingExperiments1);
        }
    };


    return (<div>
        <div className="max-w-7xl lg:h-[89vh] lg:grid lg:grid-cols-4 place-content-stretch gap-4 m-4">
            <div className="flex-col flex h-full o">
            
                <div className="bg-white/30 rounded-lg p-4 col-span-1">
                <div className="flex flex-col space-y-1 mb-6">
                    <h1 className="text-lg md:text-xl font-semibold text-gray-800">
                        Outfit AI
                    </h1>
                    <p className="text-sm text-gray-500">
                        Create unique outfits with AI
                    </p>
                </div>

                    <div className="relative w-full mt-4">
                        <label htmlFor="outfitPrompt" className="text-sm">
                            Describe your outfit
                        </label>

                        <textarea
                            id="outfitPrompt"
                            name="prompt"
                            rows="3"
                            className="w-full px-2 py-2 text-gray-700 placeholder-gray-400
                                border border-stone-400 rounded-xl
                                shadow-sm transition-all duration-200
                                focus:border-[#BE3144] focus:ring-2 focus:ring-[#BE3144]/20 focus:outline-none
                                resize-none bg-white/80 backdrop-blur-[30px] my-2"
                            placeholder="Example: A casual summer outfit with light colors, perfect for a beach walk..."
                            value={prompt}
                            onChange={handlePromptChange}
                        />
                        <div className="absolute bottom-4 right-2 text-xs text-gray-400">
                            {prompt.length} / 500
                        </div>
                    </div>

                    <div className="flex flex-col mb-4">
                        <label htmlFor="imageResolution" className="text-sm">
                            Select image resolution
                        </label>
                        <select
                            name="imageResolution"
                            className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                            value={imageResolution}
                            onChange={handleImageResolution}
                        >
                            <option value="256x256">256x256(default)</option>
                            <option value="512x512">512x512</option>
                            <option value="1024x1024">1024x1024</option>
                        </select>
                    </div>

                    <div className="flex flex-col mb-4">
                        <label htmlFor="numResults" className="text-sm">
                            Number of outfits to generate
                        </label>
                        <input
                            id="numResults"
                            name="numberResults"
                            type="number"
                            className="rounded-lg bg-white w-full my-2 shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                            value={numResults}
                            onChange={handleNumResultsChange}
                            min={1}
                            max={3}
                        />
                    </div>

                    <div onClick={() => toggleAdvancedOptions()} className="flex my-4 mx-1 justify-between items-center cursor-pointer">
                        <span className="text-sm">Advanced Options</span>
                        <div className={`transform transition-transform duration-300 ${advancedOptions ? 'rotate-180' : 'rotate-0'}`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path fillRule="evenodd" d="M5.293 9.707a1 1 0 011.414 0L10 13l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>

                    {advancedOptions && (
                        <div className="flex flex-col">
                            <div className="flex flex-col mb-4">
                                <label htmlFor="seed" className="text-sm">
                                    Seed
                                </label>
                                <input
                                    name="seed"
                                    type="number"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={seed}
                                    onChange={handleSeedChange}
                                />
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="guidanceScale" className="text-sm">
                                    Guidance scale
                                </label>
                                <input
                                    name="guidanceScale"
                                    type="number"
                                    step="0.1"
                                    max="20.0"
                                    min="0.0"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={guidanceScale}
                                    onChange={handleGuidanceScaleChange}
                                />
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="strength" className="text-sm mb-2">
                                    Strength (0.1 to 1.0)
                                </label>
                                <input
                                    name="strength"
                                    type="range"
                                    min="0.0"
                                    max="1.0"
                                    step="0.01"
                                    className="appearance-none h-2 w-full rounded-lg bg-gradient-to-r from-gray-200 to-black focus:outline-none"
                                    value={strength}
                                    onChange={handleStrengthChange}
                                />
                                <span className="text-sm mt-2">Strength: {strength}</span>
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="inferenceSteps" className="text-sm">
                                    Number of inference steps
                                </label>
                                <input
                                    name="inferenceSteps"
                                    type="number"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={inferenceSteps}
                                    onChange={handleInferenceStepsChange}
                                />
                            </div>

                        </div>
                    )}

                    <button
                        className="py-2 px-4 w-full mt-2 mx-auto text-bg bg-gradient-to-r from-red-500 to-rose-600 rounded-lg
                    text-white cursor-pointer" onClick={handleGenerateClick}>
                        Generate
                    </button>
                </div>
            </div>

            <div className="bg-white/30 rounded-lg col-span-3 h-full overflow-auto relative">
                <div className="flex">

                    {authenticated === null ?
                        <div className="flex flex-wrap gap-2 rounded-lg w-full">
                            <div className="w-full shadow rounded-lg bg-white animate-pulse">
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                            </div>
                        </div>
                        :
                        authenticated ?
                            <div className="flex flex-wrap gap-2 p-4 rounded-lg w-full">
                                {
                                    experimentsFetched && (experiments.length > 0
                                        ?
                                        experiments.map((experiment) => experiment !== undefined ?
                                            (<PictureGrid experiment={experiment} />) : (<div></div>)
                                        )
                                        :
                                        <div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2 bg-opacity-70 backdrop-blur-lg rounded-xl bg-white/60 border-2 border-rose-100'>
                                            <div className="flex flex-col w-full items-center justify-center ">
                                                <img src={NoModels} alt="error" className='object-contain h-16 object-center w-16 mb-4' />
                                                <div className="!text-xl font-semibold text-center font-OpenSans ">
                                                    <GradientFont>{"No Outfits Generated!"}</GradientFont>
                                                </div>
                                            </div>
                                            <span className='ml-4 my-4 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'>Generated outfits will be displayed here!</span>
                                        </div>
                                    )
                                }

                            </div>
                            :
                            <div className='flex flex-col w-full h-full items-center justify-center px-4 pt-4 pb-4'>
                                <div className='flex relative items-center justify-center left-0 right-0 bg-opacity-70 backdrop-blur-lg w-full h-full rounded-2xl bg-white/70 border-2 border-rose-100'>

                                    <div className='relative flex flex-row items-center justify-center h-full w-fit'>

                                        <div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2'>

                                            <div className="flex flex-row w-full items-center justify-center">
                                                <img src={errorIcon} alt="error" className='object-contain h-8 object-center w-8 mr-4' />
                                                <div className="!text-xl font-semibold text-center font-OpenSans ">
                                                    <GradientFont>{"Authentication Required"}</GradientFont>
                                                </div>
                                            </div>
                                            <span className='ml-4 my-2 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'>Please sign in or sign up to start the outfit generation.</span>

                                            <div onClick={handleLoginRedirect} className='flex flex-row mt-2 cursor-pointer items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border-1 border-solid border-[#EFCBCB] backdrop-blur-[50px] py-3 px-8'>
                                                <div className={` flex flex-row items-center justify-center w-fit h-full `}>
                                                    <span className={`!text-white text-sm font-semibold font-OpenSans leading-normal`}>Sign in</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                    {error && (
                        // <div className="fixed inset-0 z-50 flex items-center justify-center 
                        //     bg-black/20 backdrop-blur-sm">
                        //     <div className="relative w-full max-w-md mx-4">
                        //         <ErrorMessage
                        //             errorStatus={errorStatus}
                        //             errorMessage={errorMessage}
                        //             onClose={handleErrorClose}
                        //         />
                        //     </div>
                        // </div>
                        <div id="toast-default" class="fixed bottom-5 left-1/2 transform -translate-x-1/2 flex items-center w-full max-w-lg p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                                </svg>
                                <span class="sr-only">Error icon</span>
                            </div>
                            <div class="flex flex-col">
                            <div class="ms-3 text-base font-normal text-red-500">{errorStatus}</div>
                            <div class="ms-3 text-sm font-normal text-red-400">{errorMessage}</div>
                            </div>
                            <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-default" aria-label="Close" onClick={handleErrorClose}>
                                <span class="sr-only">Close</span>
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
            </div>

        </div>
    </div>);
}
