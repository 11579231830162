import React, { useState, useRef, useEffect } from 'react';
import HumanOutline from "../assets/HumanOutline.svg";
import camera from "../assets/camera.svg";
import switchCameraSvg from "../assets/switchCamera.svg";
import Spinner from './Spinner';
import workerInstance from "../workers/poseDetectionWorkerInstance";
import backArrow from "../assets/backArrow.svg";
import closeSvg from "../assets/close.svg";
import ThreeDotSpinner from './ThreeDotSpinner';

const WebcamOverlay = ({ defaultImgSrc, onUpload, handleUserModelClick, onClose, setShowUploadInstructions }) => {
    const videoRef = useRef();
    const canvasRef = useRef(null);
    const streamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(defaultImgSrc);
    const [facingMode, setFacingMode] = useState("user");
    const [loading, setLoading] = useState(true);
    const [errormsg, setErrorMsg] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isCapturing, setIsCapturing] = useState(false);
    const [cameraInput, setCameraInput] = useState(false);
    const [poseDetected, setPoseDetected] = useState(false);
    const [isPoseCorrect, setIsPoseCorrect] = useState(false);
    const [detectingPose, setDetectingPose] = useState(false);
    const hiddenUserModelFileInput = useRef(null);
    const [uploadFromGallery, setUploadFromGallery] = useState(false);
    const [uploadFromCamera, setUploadFromCamera] = useState(false);

    useEffect(() => {
        const handleMessage = (event) => {
            console.log("handleMessage is triggered", event);
            const { poseDetected, isCorrect } = event.data;
            console.log("poseDetected:", poseDetected, "isCorrect:", isCorrect);
            setPoseDetected(poseDetected);
            setIsPoseCorrect(isCorrect);
            setDetectingPose(false);
          };
      
          workerInstance.addEventListener('message', handleMessage);
      
          return () => {
            workerInstance.removeEventListener('message', handleMessage);
          };
    }, []);

    useEffect(() => {
        console.log("useEffect is triggered", cameraInput)
        if (cameraInput) {
            startCamera();
        }
        return () => {
            stopCamera(); // Cleanup function to stop camera stream on unmount
        };
    }, [cameraInput, facingMode,]);

    const startCamera = async () => {
        setLoading(true);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facingMode }
            });
            streamRef.current = stream; // Store the stream in streamRef
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play();
                };
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setErrorMsg('Error accessing the camera: ' + err);
        }
    };

    const stopCamera = () => {
        console.log("turnOffCamera is triggered", videoRef);

        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach((track) => {
                track.stop();
            });
            streamRef.current = null; // Clear the stored stream
            console.log("Camera stopped", tracks);
        }

        if (videoRef?.current) {
            videoRef.current.srcObject = null;
            console.log("Video ref srcObject set to null");
        }
        setIsCapturing(false)
    };


    useEffect(() => {
        console.log("videoRef changed:", videoRef.current);
        if (!videoRef.current || imgSrc) {
            stopCamera();
        }
    }, [videoRef.current, imgSrc]);

    const capture = () => {
        setIsCapturing(true);
        setTimer(5);

        const countdown = setInterval(() => {
            setTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(countdown);
                    const video = videoRef.current;
                    const context = canvasRef.current.getContext('2d');
                    const parent = video.parentNode;

                    // Capture the full video frame
                    const tempCanvas = document.createElement('canvas');
                    tempCanvas.width = video.videoWidth;
                    tempCanvas.height = video.videoHeight;
                    const tempContext = tempCanvas.getContext('2d');
                    tempContext.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                    const fullImage = tempContext.getImageData(0, 0, video.videoWidth, video.videoHeight);
                    
                    // start pose detection
                    console.log("fullImage:", fullImage, typeof fullImage);
                    detectPoseInImage(fullImage.data, tempCanvas.width, tempCanvas.height);

                    // Setting canvas dimensions to match the parent element dimensions
                    canvasRef.current.width = parent.clientWidth;
                    canvasRef.current.height = parent.clientHeight;

                    const aspectRatio = 9 / 16;
                    const targetWidth = canvasRef.current.width;
                    const targetHeight = canvasRef.current.height;

                    // Calculate cropping coordinates to get the center 9/16 portion
                    const sx = (video.videoWidth - (video.videoHeight * aspectRatio)) / 2;
                    const sy = 0;
                    const sWidth = video.videoHeight * aspectRatio;
                    const sHeight = video.videoHeight;

                    context.save();
                    if (facingMode === 'user') {
                        context.translate(canvasRef.current.width, 0);
                        context.scale(-1, 1);
                    }

                    // Draw the cropped and scaled image on the canvas
                    context.drawImage(tempCanvas, sx, sy, sWidth, sHeight, 0, 0, targetWidth, targetHeight);
                    context.restore();

                    setImgSrc(canvasRef.current.toDataURL('image/jpeg'));
                    setIsCapturing(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };


    const switchCamera = () => {
        setFacingMode((prevFacingMode) => (prevFacingMode === "user" ? "environment" : "user"));
    };

    const handleTryAgain = () => {
        if (uploadFromCamera) {
            setImgSrc(null);
            // Call stopCamera here to ensure stream is stopped before restarting
            stopCamera();
            startCamera();
            setPoseDetected(false);
            setIsPoseCorrect(false);
        }else if(uploadFromGallery){
            setImgSrc(null);
            setCameraInput(false);
            setPoseDetected(false);
            setIsPoseCorrect(false);
        }
    };

    const handleUpload = async () => {
        console.log("handleUpload is triggered");

        if (imgSrc && onUpload) {
            const response = await fetch(imgSrc);
            const blob = await response.blob();
            const file = new File([blob], "captured_image.jpg", { type: "image/jpeg" });
            
            onUpload(file);
            setImgSrc(null);
            setCameraInput(false);
            setPoseDetected(false);
            setIsPoseCorrect(false);
            onClose();
        }
    };

    const handleUploadFromGallery = () => {
        console.log("handleUploadFromGallery is triggered");
        setUploadFromGallery(true);
        setUploadFromCamera(false);
        hiddenUserModelFileInput.current.click();
    };

    const handleFileSelected = (event) => {
        console.log("handleFileSelected is triggered", event);
        const files = event.target.files;
        console.log("files:", files[0]);
        setDetectingPose(true);

        // Create an Image element
        const img = new Image();
        img.src = URL.createObjectURL(files[0]);
        setImgSrc(img.src);
        setCameraInput(true);

        img.onload = () => {
            // Create a canvas to draw the image
            const canvas = document.createElement('canvas');
            canvas.width = img.width; // Set canvas width
            canvas.height = img.height; // Set canvas height

            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0); // Draw the image on the canvas

            // Get the ImageData from the canvas
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

            // Now you can use imageData as needed
            console.log(imageData); // For debugging or further processing

            detectPoseInImage(imageData.data, canvas.width, canvas.height);
        };

        img.onerror = (error) => {
            console.error("Error loading image:", error);
        };
    };

    const getAspectRatio = () => (window.innerWidth > window.innerHeight ? '16/9' : '9/16');

    const detectPoseInImage = (imageData, width, height) => {  
        // Send the image data to the worker
        workerInstance.postMessage({ imageData, width, height }); 
    };

    return (
        <div className="relative flex flex-row w-full min-h-[80%] h-fit max-h-full bg-white border border-dashed border-selected rounded-lg shadow-auth-box-shadow py-4">
            <div className="flex w-full items-start justify-between absolute top-2 px-4 ">
                {cameraInput ? (<button
                    onClick={() => setCameraInput(false)}
                // className="absolute top-6 left-6 w-8 h-8 flex items-center justify-center bg-transparent hover:bg-gray-200 rounded-full"
                >
                    <img className="w-4 h-4 -scale-x-100" src={backArrow} alt="Back" />
                </button>) : <div className='hidden'>Dummy div element</div>}
                <button
                    type="button"
                    onClick={() => {
                        setCameraInput(false);
                        onClose();
                    }}
                    // className="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                    data-modal-hide="default-modal"
                >
                    <img className="w-4 h-4" src={closeSvg} alt="Close" />
                </button>
            </div>
            <div className="flex w-full ">
                {cameraInput ? (
                    <div className="flex items-center justify-center w-full h-full p-6">
                        <div className='flex flex-col w-full h-full'>
                            {!imgSrc && (
                                <div className='flex flex-col w-full h-full justify-center items-center relative'>
                                    <div className="relative flex min-w-[20%] w-auto max-w-full h-full justify-center items-center ">
                                        <video
                                            ref={videoRef}
                                            className={`rounded-lg shadow-md w-full h-full object-cover transform ${facingMode === "user" ? 'scale-x-[-1]' : ''}`}
                                            style={{ aspectRatio: '9 / 16' }}
                                            onCanPlay={() => setLoading(false)}
                                            muted
                                        />
                                        <div className='absolute inset-0 flex items-end justify-center w-full h-full'>
                                            <img src={HumanOutline} className='object-cover opacity-70 w-full h-full' alt="Human Outline" />
                                        </div>
                                        <canvas
                                            ref={canvasRef}
                                            className="hidden"
                                            width={640}
                                            height={360}
                                        ></canvas>
                                        {loading && (
                                            <div className='absolute inset-0 flex items-center justify-center'>
                                                <Spinner />
                                            </div>
                                        )}
                                        {errormsg && (
                                            <div className='absolute bottom-0 left-0 m-4 text-red-500'>
                                                {errormsg}
                                                <button
                                                    onClick={() => {
                                                        setErrorMsg(null);
                                                        // stopCamera();
                                                        onClose();
                                                    }}
                                                    className="underline text-[#ED5642] ml-2"
                                                >
                                                    Go back
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative w-full h-full mt-6 flex items-center justify-center space-x-4">
                                        <button
                                            onClick={capture}
                                            disabled={isCapturing}
                                            className="flex items-center px-4 py-2 text-[#ED5642] border-2 border-[#ED5642] border-solid rounded-full transition-all duration-300 hover:bg-[#ED5642] hover:text-white"
                                        >
                                            <img src={camera} alt="Capture" className="w-6 h-6 mr-2" />
                                            {isCapturing ? `Capturing in ${timer}s` : 'Capture photo'}
                                        </button>
                                        <button
                                            onClick={switchCamera}
                                            className="flex items-center px-4 py-2 text-[#ED5642] border-2 border-[#ED5642] border-solid rounded-full transition-all duration-300 hover:bg-[#ED5642] hover:text-white"
                                        >
                                            <img src={switchCameraSvg} alt="Switch camera" className="w-6 h-6 mr-2" />
                                            Switch camera
                                        </button>
                                        {/* <button onClick= {()=>{stopCamera()}}>click</button> */}

                                    </div>
                                </div>
                            )}
                            {imgSrc && (
                                <div className='flex flex-col w-full h-full justify-center items-center relative'>
                                    <div className="flex w-full h-full items-center justify-center relative">
                                        <img src={imgSrc} alt="Captured" className="mt-2 min-h-[60%] h-fit max-h-full rounded-lg shadow-md object-contain" />
                                    </div>
                                    <div className='mt-4 w-full'>
                                        {poseDetected && !isPoseCorrect?(
                                            <div class="p-4 mb-4 w-full text-sm text-center text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                <span class="font-medium">Error!</span> Unsupported image. We only support portrait images. Please try again with a portrait image. <br/>
                                                <a href="#" onClick={()=>{setShowUploadInstructions(true); onClose();}} className="text-red-800 underline">Check out our tutorial on how to capture a good portrait image.</a>
                                            </div>                                          

                                        ): poseDetected && isPoseCorrect? (
                                            <div class="p-4 mb-4 text-sm w-full text-center text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                                                <span class="font-medium">Success!</span> Continue to upload.
                                            </div>): detectingPose? (
                                                <div class="flex items-center justify-center w-full p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                                                <span>Validating your image. Please wait...</span>&nbsp;
                                                <ThreeDotSpinner/>
                                              </div>
                                                ):""}
                                    </div>
                                    <div className="flex space-x-4">
                                        <button
                                            onClick={handleTryAgain}
                                            disabled={ detectingPose || !poseDetected}
                                            className={`flex items-center px-4 py-2 text-[#ED5642] rounded-full transition-all duration-300 
                                                ${detectingPose ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'border-2 border-[#ED5642] border-solid hover:bg-[#ED5642] hover:text-white'}`}
                                        >
                                            Try again
                                        </button>
                                        <button
                                            onClick={handleUpload}
                                            disabled={!poseDetected || !isPoseCorrect}
                                            className={`flex items-center px-4 py-2 text-[#28A745] border-solid rounded-full transition-all duration-300 ${(!poseDetected || !isPoseCorrect) ? 'bg-gray-300 text-gray-500 cursor-not-allowed ' : 'hover:bg-[#28A745] hover:text-white border-2 border-[#28A745]'}`}
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col w-full m-6 items-center bg-[#FAEBEB] backdrop-blur-md justify-center rounded-lg border border-solid border-selected p-4 md:p-0">
                        <div
                            onClick={() => {
                                handleUploadFromGallery();
                            }}
                            className="cursor-pointer flex items-center mb-6 justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-full border border-solid border-[#EFCBCB] backdrop-blur-md py-3 px-6 w-full sm:w-auto mx-4 transition-transform transform hover:scale-105"
                        >
                            <div className="flex items-center justify-center w-fit h-full font-OpenSans">
                                <span className="text-base font-semibold text-[#F9E0E0] leading-normal">
                                    Upload From Gallery
                                </span>
                            </div>
                            <input
                                    type="file"
                                    onChange={handleFileSelected}
                                    ref={hiddenUserModelFileInput}
                                    style={{ display: 'none' }}
                                />
                        </div>
                        <div
                            onClick={() => { setCameraInput(true); setUploadFromCamera(true); setUploadFromGallery(false); }}
                            className="cursor-pointer flex items-center justify-center bg-gradient-to-r from-[#3B3F6A] to-[#22092C] rounded-full border border-solid border-[#EFCBCB] backdrop-blur-md py-3 px-6 w-full sm:w-auto mx-4 transition-transform transform hover:scale-105"
                        >
                            <div className="font-OpenSans flex items-center justify-center w-fit h-full">
                                <span className="text-base font-semibold text-center text-[#F9E0E0] leading-normal">
                                    Capture From Camera
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>


    );
};

export default WebcamOverlay;
